<template>
  <section class="section">
    <div class="container">
      <SectionHeader title="AutoCheck Batch" title-icon="fal fa-object-group" />
      <BatchDataInput
        v-if="!batch"
        :headers="['vrm', 'vin', 'mileage']"
        :loading="$wait.is('batchPrecheck')"
        @submit="precheck"
      />
      <BatchCheckOptions v-else v-bind="{ batch }" @cancel="batch = null" />
    </div>
  </section>
</template>

<script>
import { batchPrecheck } from 'modules/autocheck/services'
import BatchDataInput from 'modules/batch-data/components/BatchDataInput'
export default {
  name: 'AutoCheckBatch',
  data: () => ({ batch: null }),
  components: {
    BatchDataInput,
    BatchCheckOptions: () => import('../components/AutoCheckBatchOptions'),
    SectionHeader: () =>
      import('core/modules/section-header/components/SectionHeader')
  },
  methods: {
    async precheck({ rows }) {
      try {
        this.$wait.start('batchPrecheck')
        const batch = await batchPrecheck(rows)
        for (const vehicle of batch) {
          vehicle.failed = !Object.keys(vehicle).includes('make')
        }
        this.batch = batch
      } catch (err) {
        this.$notify(err.message)
      } finally {
        this.$wait.end('batchPrecheck')
      }
    }
  }
}
</script>
